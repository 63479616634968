import { useContext, useEffect, useRef, useState } from "react";
import { ChatConversationDetails } from "../../streamChat/types";
import { ChatContext } from "../context";
import invariant from "tiny-invariant";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { attachClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";

type ConversationQuickDetailsProps = {
  conversation: ChatConversationDetails;
  showLastMessage?: boolean;
  parentFolderId?: string;
  parentSubFolderId?: string;
};

export const ConversationQuickDetails = ({
  conversation,
  showLastMessage,
  parentFolderId,
  parentSubFolderId,
}: ConversationQuickDetailsProps) => {
  const {
    selectedConversationChannelId,
    setSelectedConversationChannelId,
    chatClient,
  } = useContext(ChatContext);

  const [latestUnreadCount, setLatestUnreadCount] = useState(
    conversation.numberOfUnreadMessages
  );
  const [latestMessage, setLatestMessage] = useState(conversation.lastMessage);

  const isSelected = selectedConversationChannelId == conversation.channelId;

  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    chatClient!
      .channel("messaging", conversation.channelId)
      .on("message.new", (event) => {
        setLatestUnreadCount(event.unread_count ?? 1);
        setLatestMessage({
          sentBy: {
            role: event.user!.role!,
            userId: event.user!.id!,
            userName: event.user!.name!,
          },
          sentOn: event.created_at!,
          text: event.message!.text!,
        });
      });

    chatClient!
      .channel("messaging", conversation.channelId)
      .on("message.read", () => {
        setLatestUnreadCount(0);
      });
  }, []);

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return combine(
      draggable({
        element: el,
        getInitialData: () => ({
          type: "conversation",
          conversationChannelId: conversation.channelId,
          parentFolderId: parentFolderId,
          parentSubFolderId: parentSubFolderId,
        }),
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false),
      }),
      dropTargetForElements({
        element: el,
        getData: ({ input, element }) => {
          const data = {
            type: "conversation",
            conversationChannelId: conversation.channelId,
            parentFolderId: parentFolderId,
            parentSubFolderId: parentSubFolderId,
          };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ["top", "bottom"],
          });
        },
        getIsSticky: () => false,
        onDragEnter: (args) => {
          if (
            args.source.data.conversationChannelId !== conversation.channelId
          ) {
            console.log("onDragEnter", args);
          }
        },
      })
    );
  }, []);

  return (
    <div
      ref={ref}
      className={`flex items-center space-x-3 p-2 -mx-2 rounded cursor-pointer ${
        isSelected ? "bg-blue-50" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedConversationChannelId(conversation.channelId);
      }}
    >
      <div
        className={`relative w-8 h-8 rounded-full flex items-center justify-center font-bold ${
          latestUnreadCount > 0
            ? "bg-ipractice-blue text-white"
            : "bg-slate-200"
        }`}
      >
        {conversation.clientName[0]}
        {latestUnreadCount > 0 ? (
          <div className="absolute top-0 right-0 w-2 h-2 rounded-full bg-green-700 animate-pulse" />
        ) : (
          <></>
        )}
      </div>
      <div>
        <div className="w-full flex items-center justify-between space-x-2">
          <h2>
            <span className="font-medium">{conversation.clientName}</span> -{" "}
            {conversation.epdPatientId}.{conversation.epdFileId}
          </h2>
        </div>
        {showLastMessage ? (
          <p className="text-xs truncate min-w-0 max-w-[245px]">
            {latestMessage?.sentBy?.userName}:{" "}
            <span className="italic">{latestMessage?.text}</span>
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
