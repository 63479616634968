import { useState } from "react";
import { Pencil, Trash, X } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../../components/ui/hover-card";
import { EditFolderName } from "./editFolderName";
import { DeleteFolderDialog } from "./deleteFolderDialog";

type ActionMenuProps = {
  isExpanded: boolean;
  folderId: string;
  subFolderId?: string;
  currentName: string;
};

export const ActionMenu = ({
  isExpanded,
  folderId,
  subFolderId,
  currentName,
}: ActionMenuProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <>
      {isEditing ? (
        <EditFolderName
          setIsEditing={setIsEditing}
          folderId={folderId}
          subFolderId={subFolderId}
          currentName={currentName}
        />
      ) : (
        <>
          <HoverCard openDelay={100}>
            <HoverCardTrigger>
              <p
                className={`cursor-pointer ${
                  isExpanded ? "font-bold" : "font-normal"
                }`}
              >
                {currentName}
              </p>
            </HoverCardTrigger>
            <HoverCardContent
              side="right"
              align="center"
              className="w-[120px] bg-slate-900 text-slate-200 space-y-2 p-2"
            >
              <div
                className="inline-flex items-center space-x-2 cursor-pointer hover:underline"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}
              >
                <div className="w-4 h-4">
                  <Pencil className="w-4 h-4" />
                </div>
                <p>Rename</p>
              </div>

              <div
                className="flex items-center space-x-2 cursor-pointer hover:underline text-red-400"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteDialogOpen(true);
                }}
              >
                <div className="w-4 h-4">
                  <Trash className="w-4 h-4" />
                </div>
                <p>Delete</p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </>
      )}
      <DeleteFolderDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        folderId={folderId}
        subFolderId={subFolderId}
      />
    </>
  );
};
