import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuGroup,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from "../../../components/ui/dropdown-menu";
import {
  ShieldCheck,
  MessageSquareText,
  Calendar,
  SendHorizontal,
  ChevronRight,
  CalendarClock,
  Mails,
  FileStack,
  FileCheck2,
  FilePen,
} from "lucide-react";
import useStores from "../../../useStores";
import { useContext } from "react";
import { NavBarOpenStateContext } from "../NavBarOpenStateContext";
import { useHistory } from "react-router-dom";
import roleNames from "../../../utils/constants/roleNames";
import { UserManagementSubMenu } from "./subMenus/userManagementSubMenu";
// import { CalendarSubMenu } from "./subMenus/calendarSubMenu";
import { CrmSubMenu } from "./subMenus/crmSubMenu";

export const AdminMenu = () => {
  const { authStore } = useStores();
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;
  const history = useHistory();

  if (!authStore.roles.includes(roleNames.ADMINISTRATOR)) {
    return <></>;
  }

  return (
    <li className="my-[3px] p-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex items-center justify-between w-full hover:text-blue-500 cursor-pointer">
            <div className="flex items-center space-x-2 w-fit">
              <div className="w-5 h-5">
                <ShieldCheck className="w-5 h-5" />
              </div>
              <p className={`${isNavBarOpen ? "visible" : "hidden"}`}>
                Administration
              </p>
            </div>
            {isNavBarOpen && (
              <div className="w-4 h-4">
                <ChevronRight className="w-4 h-4" />
              </div>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-[24rem] space-y-1"
          style={{ zIndex: 99999 }}
          side="right"
          align="end"
        >
          <DropdownMenuGroup>
            <UserManagementSubMenu />
            <DropdownMenuSeparator />
            {/* <CalendarSubMenu /> */}

            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();

                history.push("/admin/calendar-algorithm-admin");
              }}
            >
              <div className="w-4 h-4">
                <Calendar className="h-4 w-4" />
              </div>
              <p>
                <span className="font-bold">Calendar Algorithm</span>{" "}
                Administration
              </p>
            </DropdownMenuItem>

            <CrmSubMenu />

            <DropdownMenuSeparator />

            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();

                history.push("/admin/case-load-manager");
              }}
            >
              <div className="w-4 h-4">
                <MessageSquareText className="h-4 w-4" />
              </div>
              <p>
                <span className="font-bold">Caseload Manager</span>{" "}
                Administration
              </p>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="cursor-pointer flex justify-between">
                <div className="flex space-x-2 items-center">
                  <div className="w-4 h-4">
                    <CalendarClock className="h-4 w-4" />
                  </div>
                  <p>Automated messages, reminders</p>
                </div>
              </DropdownMenuSubTrigger>

              <DropdownMenuPortal>
                <DropdownMenuSubContent className="z-[99999999]">
                  <DropdownMenuItem
                    className="cursor-pointer space-x-2 flex items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        "/admin/patient-referrals/upcoming-reminders"
                      );
                    }}
                  >
                    <div className="w-4 h-4">
                      <Mails className="h-4 w-4" />
                    </div>
                    <p>CRM Upcoming appointments (email)</p>
                  </DropdownMenuItem>

                  <DropdownMenuSeparator />

                  <DropdownMenuItem
                    className="cursor-pointer space-x-2 flex items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/admin/sq48-reminders-to-complete");
                    }}
                  >
                    <div className="w-4 h-4">
                      <FilePen className="h-4 w-4" />
                    </div>
                    <p>SQ48 Reminders (Wire)</p>
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="cursor-pointer space-x-2 flex items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/admin/appointment-reminders");
                    }}
                  >
                    <div className="w-4 h-4">
                      <FilePen className="h-4 w-4" />
                    </div>
                    <p>Appointment reminders (Wire)</p>
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="cursor-pointer space-x-2 flex items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/admin/sq48-result-pdf-to-chat");
                    }}
                  >
                    <div className="w-4 h-4">
                      <FileCheck2 className="h-4 w-4" />
                    </div>
                    <p>SQ48 Result PDFs (Wire)</p>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>

            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();

                history.push("/admin/send-questionnaires-to-praktijkdata");
              }}
            >
              <div className="w-4 h-4">
                <SendHorizontal className="h-4 w-4" />
              </div>
              <p>Send questionnaires to PraktijkData</p>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};
