import moment from "moment";

export const getSimpleFormattedDate = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleDateString("nl-NL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const getSimpleFormattedDateTime = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleString("nl-NL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getSimpleFormattedDateWithoutYear = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleString("nl-NL", {
    month: "2-digit",
    day: "2-digit",
  });
};

export const isDateInPastWeeks = (date: Date) => {
  const currentDate = new Date();

  const currentYear = moment(currentDate).year();
  const selectedYear = moment(date).year();

  if (currentYear > selectedYear) return true;

  const currentWeek = moment(currentDate).week();
  const selectedWeek = moment(date).week();

  return currentWeek > selectedWeek;
};

export const isPeriodInPastWeeks = (period: { year: number; week: number }) => {
  const currentDate = new Date();

  const currentYear = moment(currentDate).year();
  const selectedYear = period.year;

  if (currentYear > selectedYear) return true;

  const currentWeek = moment(currentDate).week();
  const selectedWeek = period.week;

  return currentWeek > selectedWeek;
};

export const isPeriodInFutureWeeks = (period: {
  year: number;
  week: number;
}) => {
  const currentDate = new Date();

  const currentYear = moment(currentDate).year();
  const selectedYear = period.year;

  if (currentYear < selectedYear) return true;

  const currentWeek = moment(currentDate).week();
  const selectedWeek = period.week;

  return currentWeek < selectedWeek;
};

export const getCurrentMondayAndFriday = () => {
  let now = new Date();
  let day = now.getDay();
  let diffToMonday = now.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is Sunday
  let diffToFriday = diffToMonday + 4; // 4 days after Monday

  let monday = new Date(now.setDate(diffToMonday));
  monday.setHours(0, 0, 0, 0); // set time to 00:00:00.000

  let friday = new Date(now.setDate(diffToFriday));
  friday.setHours(23, 59, 59, 999); // set time to 23:59:59.999

  return {
    monday: moment(monday).utc(true).toDate(),
    friday: moment(friday).utc(true).toDate(),
  };
};

export const getNextMonday = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);
  return nextMonday;
};
