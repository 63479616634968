import { useEffect, useState } from "react";
import { Input } from "../../../components/ui/input";
import useStores from "../../../useStores";
import roleNames from "../../../utils/constants/roleNames";
import { useDebounce } from "use-debounce";
import { useSavePreIntakeTarget } from "../hooks";

export const PreIntakeCountCell = ({
  psychologistEpdId,
  startOfWeek,
  actual,
  originalTarget,
}: {
  psychologistEpdId: number;
  startOfWeek: Date;
  actual: number;
  originalTarget: number;
}) => {
  const { authStore } = useStores();
  const isReadOnly = !authStore.roles.includes(roleNames.ADMINISTRATOR);
  const [hasMounted, setHasMounted] = useState(false);

  const [target, setTarget] = useState(originalTarget);

  const [debouncedTarget] = useDebounce(target, 500);

  const { mutate: save, isLoading: isSaving } = useSavePreIntakeTarget(() => {
    alert("Something went wrong - please ping the dev team");
  });

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      return;
    }

    save({
      psychologistEpdId,
      weekStartDate: startOfWeek,
      target: debouncedTarget,
    });
  }, [debouncedTarget]);

  return (
    <div>
      <div className="flex items-center space-x-2">
        <p className="font-bold">Actual: </p>
        <p>{actual}</p>
      </div>
      <div className="pt-1 flex items-center space-x-2">
        <p className="font-bold">Max: </p>
        {isReadOnly ? (
          <p>{originalTarget}</p>
        ) : (
          <Input
            className="w-[50px]"
            disabled={isSaving}
            value={target}
            onChange={(e: any) => {
              setTarget(Number(e.target.value));
            }}
          />
        )}
      </div>
    </div>
  );
};
