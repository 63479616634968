import axios from "axios";
import { useQuery } from "react-query";
import { ChatConversationDetails } from "../streamChat/types";

export const useGetAllMyChatDetails = (isEnabled: boolean) => {
  const query = useQuery<{ conversations: ChatConversationDetails[] }>(
    ["my-chat", "details"],
    async () => {
      const res = await axios.get(`mychat`);
      return res.data;
    },
    {
      enabled: isEnabled,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetExactChatDetails = (channelId: string | null) => {
  const query = useQuery<ChatConversationDetails>(
    ["my-chat", "details", channelId],
    async () => {
      const res = await axios.get(`mychat/${channelId}`);
      return res.data;
    },
    {
      enabled: channelId != null,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
