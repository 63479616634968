import { useEffect, useState } from "react";
import { Input } from "../../../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { Skeleton } from "../../../../components/ui/skeleton";
import { useGetPreIntakeReport } from "../../hooks";
import { CaseLoadProgressWithOccupancyForecast } from "../../types";
import { useDebounce } from "use-debounce";
import { Wrapper } from "../../../../pages/preIntake/report/wrapper";
import { EditableOnlinePsyPreIntakeComment } from "../../../../pages/preIntake/report/editableOnlinePsyPreIntakeComment";
import { DetailsColumn } from "../../../../pages/preIntake/report/detailsColumn";
import useStores from "../../../../useStores";
import roleNames from "../../../../utils/constants/roleNames";
import {
  getSimpleFormattedDateWithoutYear,
  getNextMonday,
} from "../../../../utils/dateUtils";
import { PreIntakeCountCell } from "../../../../pages/preIntake/report/preIntakeCountCell";
import { SchedulePreIntakeLocationAndTeamFilter } from "./schedulePreIntakeLocationAndTeamFilter";
import { LocationWithTeamTag } from "../../../../shared/hooks/useGetLocationWithTeamTags";

export const SchedulePreIntakeViaCaseLoadData = ({
  setSelectedPsychologistEpdId,
  defaultLocationFilter,
  defaultTeamFilter,
  asCard,
}: {
  setSelectedPsychologistEpdId: (newId: number) => void;
  defaultLocationFilter: string | null;
  defaultTeamFilter: number | null;
  asCard: boolean;
}) => {
  const { authStore } = useStores();

  const [isCaseLoadDataExpanded, setIsCaseLoadDataExpanded] = useState(true);
  const [searchTermForOnlinePsychologist, setSearchTermForOnlinePsychologist] =
    useState("");

  const [debouncedSearchTermForOnlinePsychologist] = useDebounce(
    searchTermForOnlinePsychologist,
    500
  );

  const [selectedLocationAndTeam, setSelectedLocationAndTeam] =
    useState<LocationWithTeamTag | null>(null);

  useEffect(() => {
    let result = allCaseLoads;

    const locationFilter = (x: CaseLoadProgressWithOccupancyForecast) => {
      if (
        selectedLocationAndTeam == null ||
        selectedLocationAndTeam.locationName === "all"
      )
        return true;

      return (
        x.locationTags.includes(selectedLocationAndTeam.locationName) &&
        selectedLocationAndTeam.teamId &&
        x.teamTags.includes(selectedLocationAndTeam.teamId.toString())
      );
    };

    const searchTermFilter = (x: CaseLoadProgressWithOccupancyForecast) => {
      if (
        debouncedSearchTermForOnlinePsychologist == null ||
        debouncedSearchTermForOnlinePsychologist.length === 0
      ) {
        return true;
      }

      return x.name
        .toLowerCase()
        .includes(debouncedSearchTermForOnlinePsychologist.toLowerCase());
    };

    result = result?.filter(locationFilter)?.filter(searchTermFilter);

    setMutableCaseLoads(result ?? []);
  }, [debouncedSearchTermForOnlinePsychologist, selectedLocationAndTeam]);

  const [mutableCaseLoads, setMutableCaseLoads] = useState<
    CaseLoadProgressWithOccupancyForecast[]
  >([]);

  const { data: allCaseLoads, isLoading: isLoadingCaseLoads } =
    useGetPreIntakeReport((data) => {
      setMutableCaseLoads(data);
    });

  const nextMonday = getNextMonday();

  const addDays = (date: Date, days: number) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const renderBody = () => {
    return (
      <div
        className={`${
          isCaseLoadDataExpanded
            ? `visible ${
                asCard ? "p-2 border rounded-b -mt-px bg-slate-50/10" : ""
              }`
            : "hidden"
        }`}
      >
        <div className="flex items-center space-x-2 w-full">
          <Input
            placeholder="Search for online psychologist..."
            className="my-2 w-1/3"
            autoFocus
            value={searchTermForOnlinePsychologist}
            onChange={(event) => {
              setSearchTermForOnlinePsychologist(event.target.value);
            }}
          />

          <SchedulePreIntakeLocationAndTeamFilter
            isLoadingCaseLoads={isLoadingCaseLoads}
            defaultLocationFilter={defaultLocationFilter}
            defaultTeamFilter={defaultTeamFilter}
            selectedLocationAndTeam={selectedLocationAndTeam}
            setSelectedLocationAndTeam={setSelectedLocationAndTeam}
          />
        </div>

        {isLoadingCaseLoads ? (
          <div>
            <Skeleton className="h-12 w-full rounded" />
            <Skeleton className="h-8 w-full rounded mt-1" />
            <Skeleton className="h-8 w-full rounded mt-1" />
            <Skeleton className="h-8 w-full rounded mt-1" />
            <Skeleton className="h-8 w-full rounded mt-1" />
            <Skeleton className="h-8 w-full rounded mt-1" />
            <Skeleton className="h-8 w-full rounded mt-1" />
          </div>
        ) : (
          <div className="max-h-[50vh] max-w-[80vw] overflow-auto">
            <Table overflowManagedExternally className="border overflow-scroll">
              <TableHeader className="border-b">
                <TableRow>
                  <TableHead className="min-w-[200px] sticky left-0 z-20 border-r">
                    Online Psychologist
                  </TableHead>
                  <TableHead className="min-w-[150px]">Details</TableHead>
                  <TableHead className="min-w-[100px]">Caseload</TableHead>
                  <TableHead className="min-w-[170px]">
                    Work location(s)
                  </TableHead>
                  <TableHead className="min-w-[100px]">Team</TableHead>
                  <TableHead className="min-w-[120px] pl-4">
                    <div>
                      <p>Pre-Intakes</p>
                      <p className="text-xs">
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * -1)
                        )}{" "}
                        -{" "}
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * -1 + 4)
                        )}
                      </p>
                    </div>
                  </TableHead>

                  <TableHead className="min-w-[120px] pl-4">
                    <div>
                      <p className="text-xs pt-4">
                        {getSimpleFormattedDateWithoutYear(nextMonday)} -{" "}
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 4)
                        )}
                      </p>
                    </div>
                  </TableHead>

                  <TableHead className="min-w-[120px] pl-4">
                    <div>
                      <p className="text-xs pt-4">
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 1)
                        )}{" "}
                        -{" "}
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 1 + 4)
                        )}
                      </p>
                    </div>
                  </TableHead>

                  <TableHead className="min-w-[120px] pl-4">
                    <div>
                      <p className="text-xs pt-4">
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 2)
                        )}{" "}
                        -{" "}
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 2 + 4)
                        )}
                      </p>
                    </div>
                  </TableHead>

                  <TableHead className="min-w-[120px] pl-4">
                    <div>
                      <p className="text-xs pt-4">
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 3)
                        )}{" "}
                        -{" "}
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 3 + 4)
                        )}
                      </p>
                    </div>
                  </TableHead>

                  <TableHead className="min-w-[120px] pl-4">
                    <div>
                      <p className="text-xs pt-4">
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 4)
                        )}{" "}
                        -{" "}
                        {getSimpleFormattedDateWithoutYear(
                          addDays(nextMonday, 7 * 4 + 4)
                        )}
                      </p>
                    </div>
                  </TableHead>

                  <TableHead className="min-w-[250px]">Comment</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {mutableCaseLoads.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      No online psychologists found
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {mutableCaseLoads!
                      .toSorted(
                        (a, b) => a.totalPatientCount - b.totalPatientCount
                      )!
                      .map((x) => (
                        <TableRow key={x.name} className="cursor-pointer">
                          <TableCell
                            className="min-w-[200px] sticky left-0 z-10 border-r bg-slate-100"
                            // on click on each cell, except the last (comment cell), hence can't be put on the row itself the onclick (would mess with the comment cell)
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                          >
                            {x.name}
                          </TableCell>
                          <TableCell
                            // on click on each cell, except the last (comment cell), hence can't be put on the row itself the onclick (would mess with the comment cell)
                            onClick={() => {
                              if (
                                !authStore.roles.includes(
                                  roleNames.ADMINISTRATOR
                                )
                              ) {
                                setSelectedPsychologistEpdId(x.epdId);
                              }
                            }}
                            className="min-w-[150px]"
                          >
                            <DetailsColumn
                              psychologistEpdId={x.epdId}
                              originalTotalWorkingHours={x.totalWorkingHours}
                              originalWorkingHoursAsOnlinePsychologist={
                                x.workingHoursAsOnlinePsychologist
                              }
                              originalPositionTrajectory={x.positionTrajectory}
                            />
                          </TableCell>
                          <TableCell
                            className="py-1 min-w-[100px]"
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                          >
                            <p className="text-lg">{x.totalPatientCount}</p>
                            <p className="text-xs pt-1">
                              Included:{" "}
                              {x.totalPatientCount - x.excludedPatientCount}
                            </p>
                            <p className="text-xs">
                              Excluded: {x.excludedPatientCount}
                            </p>
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className={`py-1 min-w-[170px] ${
                              x.locationTags.length == 0
                                ? "opacity-50"
                                : "opacity-100"
                            }`}
                          >
                            {x.locationTags.length == 0
                              ? "N/A"
                              : x.locationTags.join(", ")}
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className={`min-w-[100px] py-1 ${
                              x.teamTags.length == 0
                                ? "opacity-50"
                                : "opacity-100"
                            }`}
                          >
                            {x.teamTags.length == 0
                              ? "N/A"
                              : x.teamTags.join(", ")}
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className="min-w-[120px] pl-4 py-1"
                          >
                            <PreIntakeCountCell
                              psychologistEpdId={x.epdId}
                              startOfWeek={addDays(nextMonday, 7 * -1)}
                              actual={
                                x.futurePreIntakesCount.preIntakesThisWeek
                              }
                              originalTarget={
                                x.preIntakeTargets.preIntakesThisWeek
                              }
                            />
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className="min-w-[120px] pl-4 py-1"
                          >
                            <PreIntakeCountCell
                              psychologistEpdId={x.epdId}
                              startOfWeek={nextMonday}
                              actual={x.futurePreIntakesCount.preIntakesIn1Week}
                              originalTarget={
                                x.preIntakeTargets.preIntakesIn1Week
                              }
                            />
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className="min-w-[120px] pl-4 py-1"
                          >
                            <PreIntakeCountCell
                              psychologistEpdId={x.epdId}
                              startOfWeek={addDays(nextMonday, 7 * 1)}
                              actual={
                                x.futurePreIntakesCount.preIntakesIn2Weeks
                              }
                              originalTarget={
                                x.preIntakeTargets.preIntakesIn2Weeks
                              }
                            />
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className="min-w-[120px] pl-4 py-1"
                          >
                            <PreIntakeCountCell
                              psychologistEpdId={x.epdId}
                              startOfWeek={addDays(nextMonday, 7 * 2)}
                              actual={
                                x.futurePreIntakesCount.preIntakesIn3Weeks
                              }
                              originalTarget={
                                x.preIntakeTargets.preIntakesIn3Weeks
                              }
                            />
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className="min-w-[120px] pl-4 py-1"
                          >
                            <PreIntakeCountCell
                              psychologistEpdId={x.epdId}
                              startOfWeek={addDays(nextMonday, 7 * 3)}
                              actual={
                                x.futurePreIntakesCount.preIntakesIn4Weeks
                              }
                              originalTarget={
                                x.preIntakeTargets.preIntakesIn4Weeks
                              }
                            />
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              setSelectedPsychologistEpdId(x.epdId);
                            }}
                            className="min-w-[120px] pl-4 py-1"
                          >
                            <PreIntakeCountCell
                              psychologistEpdId={x.epdId}
                              startOfWeek={addDays(nextMonday, 7 * 4)}
                              actual={
                                x.futurePreIntakesCount.preIntakesIn5Weeks
                              }
                              originalTarget={
                                x.preIntakeTargets.preIntakesIn5Weeks
                              }
                            />
                          </TableCell>

                          <TableCell className="min-w-[250px] py-1">
                            <EditableOnlinePsyPreIntakeComment
                              psychologistEpdId={x.epdId}
                              originalComment={x.adminComments}
                            />
                          </TableCell>
                        </TableRow>
                      ))}

                    <TotalRow data={mutableCaseLoads} />
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {asCard ? (
        <section className="pt-4">
          <Wrapper
            isCaseLoadDataExpanded={isCaseLoadDataExpanded}
            setIsCaseLoadDataExpanded={setIsCaseLoadDataExpanded}
          >
            {renderBody()}
          </Wrapper>
        </section>
      ) : (
        <>{renderBody()}</>
      )}
    </>
  );
};

const TotalRow = ({
  data,
}: {
  data: CaseLoadProgressWithOccupancyForecast[];
}) => {
  return (
    <TableRow>
      <TableCell className="min-w-[200px] sticky left-0 z-10 border-r bg-slate-100 font-bold">
        Total
      </TableCell>

      <TableCell className="min-w-[150px]" />
      <TableCell className="py-1 min-w-[100px]" />
      <TableCell className="py-1 min-w-[170px]" />
      <TableCell className="min-w-[100px] py-1" />

      <TableCell className="min-w-[120px] pl-4 py-1">
        <p>
          <span className="font-bold">Actual:</span>{" "}
          {data
            .map((x) => x.futurePreIntakesCount.preIntakesThisWeek)
            .reduce((a, b) => a + b, 0)}
        </p>
        <p>
          <span className="font-bold">Max:</span>{" "}
          {data
            .map((x) => x.preIntakeTargets.preIntakesThisWeek)
            .reduce((a, b) => a + b, 0)}
        </p>
      </TableCell>

      <TableCell className="min-w-[120px] pl-4 py-1">
        <p>
          <span className="font-bold">Actual:</span>{" "}
          {data
            .map((x) => x.futurePreIntakesCount.preIntakesIn1Week)
            .reduce((a, b) => a + b, 0)}
        </p>
        <p>
          <span className="font-bold">Max:</span>{" "}
          {data
            .map((x) => x.preIntakeTargets.preIntakesIn1Week)
            .reduce((a, b) => a + b, 0)}
        </p>
      </TableCell>

      <TableCell className="min-w-[120px] pl-4 py-1">
        <p>
          <span className="font-bold">Actual:</span>{" "}
          {data
            .map((x) => x.futurePreIntakesCount.preIntakesIn2Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
        <p>
          <span className="font-bold">Max:</span>{" "}
          {data
            .map((x) => x.preIntakeTargets.preIntakesIn2Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
      </TableCell>

      <TableCell className="min-w-[120px] pl-4 py-1">
        <p>
          <span className="font-bold">Actual:</span>{" "}
          {data
            .map((x) => x.futurePreIntakesCount.preIntakesIn3Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
        <p>
          <span className="font-bold">Max:</span>{" "}
          {data
            .map((x) => x.preIntakeTargets.preIntakesIn3Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
      </TableCell>

      <TableCell className="min-w-[120px] pl-4 py-1">
        <p>
          <span className="font-bold">Actual:</span>{" "}
          {data
            .map((x) => x.futurePreIntakesCount.preIntakesIn4Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
        <p>
          <span className="font-bold">Max:</span>{" "}
          {data
            .map((x) => x.preIntakeTargets.preIntakesIn4Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
      </TableCell>

      <TableCell className="min-w-[120px] pl-4 py-1">
        <p>
          <span className="font-bold">Actual:</span>{" "}
          {data
            .map((x) => x.futurePreIntakesCount.preIntakesIn5Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
        <p>
          <span className="font-bold">Max:</span>{" "}
          {data
            .map((x) => x.preIntakeTargets.preIntakesIn5Weeks)
            .reduce((a, b) => a + b, 0)}
        </p>
      </TableCell>

      <TableCell className="min-w-[250px] py-1" />
    </TableRow>
  );
};
